import { Routes } from "./routes";
import { GAEventAction, GAEventCategory, GAEventLabel, GAEvent } from "./ga";

export const INVITE_STATUS = {
  SENT: "SENT",
  ACCEPTED: "ACCEPTED",
};

export const INVITE_TYPE = {
  // use website until token is not expired
  TOKEN: "TOKEN",
  // registration required
  USER: "USER",
};

export enum DELIVERY_METHODS {
  EMAIL = "EMAIL",
  TELEGRAM = "TELEGRAM",
  // SIGNAL: 'SIGNAL',
}

export enum TELEGRAM_STATUS {
  PENDING = "PENDING",
  ACTIVATED = "ACTIVATED",
}

export enum ALERT_OBJECT_TYPES {
  ASSET = "asset",
  PAIR = "pair",
}

// TODO: add bigtrade/liquidity change etc
export enum ALERT_TYPES {
  PRICE_CHANGE = "price_change",
  VOLUME_CHANGE = "volume_change",
}

export enum RULE_OBJECT_TYPE {
  ASSET = "asset_object_rule",
  TICKER = "ticker_object_rule",
  CONTRACT = "contract_object_rule",
  EXCHANGE = "exchange_object_rule",
}

export enum RULE_METRIC {
  PRICE = "asset_price_change",
  VOLUME = "asset_volume_change",
}

export enum RULE_CONDITION {
  CHANGE = "asset_price_change_percentage",
  TARGET = "asset_price_target_price",
}

export const DEFAULT_INTERVALS = {
  DAY: {
    title: "24h",
    slug: "1d",
    value: "DAY",
  },
  WEEK: {
    title: "7d",
    slug: "1w",
    value: "WEEK",
  },
  MONTH: {
    title: "30d",
    slug: "1m",
    value: "MONTH",
  },
};

export const DEFAULT_INTERVALS_ANOTHER_FORMAT = {
  DAY: {
    title: "Day",
    slug: "daily",
    value: "DAY",
  },
  WEEK: {
    title: "Week",
    slug: "weekly",
    value: "WEEK",
  },
  MONTH: {
    title: "Month",
    slug: "monthly",
    value: "MONTH",
  },
};

export const DEFAULT_INTERVALS_ARRAY = Array.from(
  Object.entries(DEFAULT_INTERVALS),
).map(([k, v]) => {
  return {
    value: k,
    ...v,
  };
});

export const DEFAULT_INTERVALS_ANOTHER_FORMAT_ARRAY = Array.from(
  Object.entries(DEFAULT_INTERVALS_ANOTHER_FORMAT),
).map(([k, v]) => {
  return {
    value: k,
    ...v,
  };
});

export const DEFAULT_CURRENCIES = {
  USD: {
    title: "USD",
    slug: "usd",
  },
  BTC: {
    title: "BTC",
    slug: "btc",
  },
  ETH: {
    title: "ETH",
    slug: "eth",
  },
};

export interface AlertDelivery {
  method: DELIVERY_METHODS;
  email?: string;
  tg?: {
    username: string;
    activationStatus: TELEGRAM_STATUS;
    mute: boolean;
  };
  confirmationToken?: string;
}

export const DEFAULT_CURRENCIES_ARRAY = Array.from(
  Object.entries(DEFAULT_CURRENCIES),
).map(([k, v]) => {
  return {
    value: k,
    ...v,
  };
});

export interface NavigationItemBase {
  name: string;
  Icon?: (NavigationIconType) => JSX.Element;
  gaEvent?: GAEvent;
}

export interface NavigationItem extends NavigationItemBase {
  path: string;
  auth?: boolean;
}

export interface ParentNavigationItem extends NavigationItemBase {
  items: NavigationItem[];
  path?: string;
  auth?: boolean;
}

type NavigationIconType = {
  themeType: string;
  selected: boolean;
  base: string;
};

interface GetNavigationItems {
  isMobile?: boolean;
  isAuthenticated?: boolean;
}

const NavigationIcon = ({ themeType, base, selected }: NavigationIconType) => {
  let url = `/${base}`;

  if (themeType === "dark") {
    url += "-dark";
  }

  if (selected === true) {
    url += "-selected";
  }

  url += ".svg";

  return <img src={url} />;
};

export const getNavigation = ({
  isMobile = false,
  isAuthenticated = false,
}: GetNavigationItems = {}): Array<NavigationItem | ParentNavigationItem> => {
  const items: Array<NavigationItem | ParentNavigationItem> = [
    {
      auth: true,
      name: "Terminal",
      Icon: ({ themeType, selected }) => (
        <NavigationIcon
          base="terminal-icon"
          themeType={themeType}
          selected={selected}
        />
      ),
      path: process.env.NEXT_PUBLIC_TERMINAL_URL,
      gaEvent: {
        action: GAEventAction.Terminal,
        category: GAEventCategory.Header,
        label: GAEventLabel.Terminal,
      },
    },
    {
      name: "Markets",
      Icon: ({ themeType, selected }) => (
        <NavigationIcon
          base="markets-icon"
          themeType={themeType}
          selected={selected}
        />
      ),
      items: [
        {
          auth: true,
          name: "Assets",
          path: "/assets",
          gaEvent: {
            action: GAEventAction.Markets,
            category: GAEventCategory.Header,
            label: "Assets",
          },
        },
        {
          auth: true,
          name: "Exchanges",
          path: "/exchanges",
          gaEvent: {
            action: GAEventAction.Markets,
            category: GAEventCategory.Header,
            label: "Exchanges",
          },
        },
        // {
        //   auth: true,
        //   name: "Dexes",
        //   path: "/dexes",
        //   gaEvent: {
        //     action: GAEventAction.Markets,
        //     category: GAEventCategory.Header,
        //     label: "Dexes",
        //   },
        // },
        {
          auth: true,
          name: "DeFi",
          path: "/defi",
          gaEvent: {
            action: GAEventAction.Markets,
            category: GAEventCategory.Header,
            label: "DeFi",
          },
        },
        {
          auth: true,
          name: "ICOs & IEOs",
          path: "/icos-ieos",
          gaEvent: {
            action: GAEventAction.Markets,
            category: GAEventCategory.Header,
            label: "ICOs & IEOs",
          },
        },
        {
          auth: true,
          name: "Liquidity",
          path: "/liquidity",
          gaEvent: {
            action: GAEventAction.Markets,
            category: GAEventCategory.Header,
            label: "Liquidity",
          },
        },
        {
          auth: true,
          name: "P2P",
          path: "/p2p-transactions",
          gaEvent: {
            action: GAEventAction.Markets,
            category: GAEventCategory.Header,
            label: "P2P",
          },
        },
      ],
    },
    {
      name: "Labs",
      // path: "/labs",
      Icon: ({ themeType, selected }) => (
        <NavigationIcon
          base="labs-icon"
          themeType={themeType}
          selected={selected}
        />
      ),
      items: [
        {
          auth: true,
          name: "Crypto Lab",
          path: "/labs/labs-comparison",
          gaEvent: {
            action: GAEventAction.Labs,
            category: GAEventCategory.Header,
            label: "Crypto Lab",
          },
        },
        {
          auth: true,
          name: "Trade Simulator",
          path: "/labs/simulator",
          gaEvent: {
            action: GAEventAction.Labs,
            category: GAEventCategory.Header,
            label: "Trade Simulator",
          },
        },
        {
          auth: true,
          name: "Correlation",
          path: "/labs/correlation",
          gaEvent: {
            action: GAEventAction.Labs,
            category: GAEventCategory.Header,
            label: "Correlation",
          },
        },
        // {
        //   auth: true,
        //   name: "Arbitrage Arena",
        //   path: "/labs/arbitrage-arena",
        //   gaEvent: {
        //     action: GAEventAction.Labs,
        //     category: GAEventCategory.Header,
        //     label: "Arbitrage Arena",
        //   },
        // },
        {
          auth: true,
          name: "Dominance",
          path: "/labs/dominance",
          gaEvent: {
            action: GAEventAction.Labs,
            category: GAEventCategory.Header,
            label: "Dominance",
          },
        },
      ],
    },
    {
      auth: true,
      name: "Portfolio",
      Icon: ({ themeType, selected }) => (
        <NavigationIcon
          base="shopping-icon"
          themeType={themeType}
          selected={selected}
        />
      ),
      path: "/portfolio",
      gaEvent: {
        action: GAEventAction.Portfolio,
        category: GAEventCategory.Header,
        label: "Portfolio",
      },
    },
    {
      auth: true,
      name: "News",
      Icon: ({ themeType, selected }) => (
        <NavigationIcon
          base="globe-icon"
          themeType={themeType}
          selected={selected}
        />
      ),
      path: "/news",
      gaEvent: {
        action: GAEventAction.News,
        category: GAEventCategory.Header,
        label: "News",
      },
    },
  ];

  if (!isMobile) {
    items.push(
      {
        name: "Privacy Policy",
        path: "/privacy-policy",
        gaEvent: {
          action: GAEventAction.PrivacyPolicy,
          category: GAEventCategory.Footer,
          label: GAEventLabel.PrivacyPolicy,
        },
      },
      {
        name: "Terms Of Service",
        path: "/terms-of-service",
        gaEvent: {
          action: GAEventAction.TermsOfService,
          category: GAEventCategory.Footer,
          label: GAEventLabel.TermsOfService,
        },
      },
    );

    if (!isAuthenticated) {
      items.push({
        name: "Login",
        path: Routes.Login,
        gaEvent: {
          action: GAEventAction.Login,
          category: GAEventCategory.Footer,
          label: GAEventLabel.Login,
        },
      });
    }
  }

  return items;
};

export const isParentNavigationItem = (
  item: NavigationItem | ParentNavigationItem,
): item is ParentNavigationItem => {
  return typeof (item as ParentNavigationItem).items !== "undefined";
};

export const PAGE_SIZE = parseInt(process.env.OBJECTS_PER_PAGE) || 20;
export const PREVIEW_PAGE_SIZE = 20;
export const PREVIEW_PAGE_SIZE_FOR_ASSETS_PAGE = 20;

export interface LabItemType {
  Icon: keyof JSX.IntrinsicElements;
  title: string;
  subTitle: string;
  path: string;
}
