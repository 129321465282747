import { Switch, FormControlLabel, Typography } from "@mui/material";
import { makeStyles } from "@lib/themes";
import { lightTheme, darkTheme } from "@lib/themes";
import { ITheme } from "@lib/themes/types";
import { useAppTheme } from "@components/app/use-app-theme";

const useStyles = makeStyles((theme: ITheme) => ({
  label: {
    marginLeft: 0,
    marginRight: 0,
    "& .MuiFormControlLabel-label": {
      marginLeft: 0,
      marginRight: theme.spacing(1),
    },
  },
  switchRoot: {
    width: 32,
    height: 16,
    padding: 0,
    overflow: "visible",
  },
  switchTrack: {
    opacity: 1,
    border: `1px solid ${theme.palette.switch.border}`,
    background: `${theme.palette.switch.track} !important`,

    "$switchBase$switchChecked + &": {
      opacity: 1,
      // border: "1px solid #21212A",
      background: "transparent",
    },
  },
  // TODO: is $ allowed with tss-react?
  // TODO: shouldn't be important but there is a bug
  switchBase: {
    top: "-13px !important",
    left: "-12px !important",

    "&$switchChecked": {
      transform: "translateX(14px)",
    },
  },
  switchChecked: {},
  switchInput: {
    width: "100% !important",
    left: "0% !important",
  },
  switchImg: {
    width: 24,
    height: 24,
  },
}));

export default function ThemeSwitch() {
  const { theme, setTheme } = useAppTheme();
  const classes = useStyles();

  const label = "";
  // const label = (theme.palette.type === "dark" ? "Dark" : "Light") + " Theme"

  return (
    <FormControlLabel
      className={classes.label}
      control={
        <Switch
          checked={theme.palette.mode === "light"}
          icon={
            <img className={classes.switchImg} src="/dark-theme-switch.svg" />
          }
          checkedIcon={
            <img className={classes.switchImg} src="/light-theme-switch.svg" />
          }
          classes={{
            root: classes.switchRoot,
            switchBase: classes.switchBase,
            track: classes.switchTrack,
            input: classes.switchInput,
            checked: classes.switchChecked,
          }}
          onChange={() => {
            return setTheme(
              theme.palette.mode === "light" ? darkTheme : lightTheme,
            );
          }}
        />
      }
      label={
        <Typography variant="body2" color="textPrimary">
          {label}
        </Typography>
      }
      labelPlacement="start"
    />
  );
}
