export const appScrollBar = (theme) => ({
  "&::-webkit-scrollbar": {
    width: 8,
    height: 8,
    // borderRadius: 4,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: theme.palette.scrollbar.app.track,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "2px",
    backgroundColor: theme.palette.scrollbar.app.thumb,
  },
});

export const appListScrollBar = (theme) => ({
  "&::-webkit-scrollbar": {
    width: 8,
    height: 8,
    // borderRadius: 4,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: theme.palette.scrollbar.list.track,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "2px",
    backgroundColor: theme.palette.scrollbar.list.thumb,
  },
});

export const selectScrollBar = (theme) => ({
  "&::-webkit-scrollbar": {
    width: 16,
    // borderRadius: 4,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: theme.palette.scrollbar.list.track,
  },
  "&::-webkit-scrollbar-thumb": {
    minHeight: 10,
    borderRadius: 6,
    backgroundColor: theme.palette.scrollbar.list.thumb,
    border: "4px solid transparent",
    backgroundClip: "content-box",
  },
});

export const hideScrollBar = {
  "::-webkit-scrollbar": {
    width: 0,
    background: "transparent",
  },
};
