import typography from "./typography";

export default {
  typography: {
    fontFamily: "Source Sans Pro",
    ...typography,
  },
  components: {
    MuiTypography: {
      display: "block",
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
};
