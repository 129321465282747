import React from "react";
import NextLink, { LinkProps } from "next/link";
import { useAuth } from "@components/app";
import { Routes } from "@lib/routes";

export interface AuthLinkProps extends LinkProps {
  auth?: boolean;
}

export default function AuthLink({
  href,
  auth = true,
  ...props
}: React.PropsWithChildren<AuthLinkProps>) {
  const { isAuthenticated } = useAuth();

  return (
    <NextLink
      href={
        !isAuthenticated && !!auth
          ? `${Routes.Login}?redirect=${
              typeof href === "string" ? href : href.pathname
            }`
          : href
      }
      {...props}
    />
  );
}
