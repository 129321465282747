export default {
  red: {
    main: "#F04755",
    hover: "#C75A5A",
    selected: "#BD5050",
  },
  gray: {
    main: "#707070",
    hover: "#7B7B7B",
    selected: "#8C8C8C",
  },
};
