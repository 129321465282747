import { ITheme } from "@lib/themes/types";
import { Container, Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@lib/themes";
import { setCookie, parseCookies } from "nookies";
import { useEffect, useState } from "react";

const gpdrCookieKey = "___tloukneenr_gpdr";

const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    width: "auto",
    margin: 0,
    overflow: "hidden",
    borderTopLeftRadius: theme.spacing(),
    borderTopRightRadius: theme.spacing(),
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1280,
    backgroundColor: theme.palette.rowAlmostBackground.main,
  },
  root: {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    zIndex: 99999,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
  },
  contentContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  part1: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  logo: {
    width: 30,
    height: 60,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  content: {
    margin: theme.spacing(2.5),
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },
  },
  button: {
    minWidth: 100,
    margin: theme.spacing(2),
    width: "auto",
  },
}));

const content =
  "This website uses cookies to offer you better browsing experience, analytics and personalize content  as described in our Privacy Policy.  Continue to use this site, to accept the use of cookies.";

const GPDRBanner = () => {
  const classes = useStyles();
  const [isAccepted, setAccepted] = useState(null);
  const isAcceptedGPDR = () => parseCookies()[gpdrCookieKey] === "accepted";

  useEffect(() => {
    setAccepted(isAcceptedGPDR());
  }, []);

  const onClickAccept = () => {
    setCookie(null, gpdrCookieKey, "accepted", {
      maxAge: 30 * 24 * 60 * 60,
    });
    setAccepted(true);
  };

  if (isAccepted === null || isAccepted) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Container
        maxWidth={false}
        classes={{
          root: classes.container,
        }}
        disableGutters={true}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.contentContainer}>
          <Box display="flex" alignItems="center" className={classes.part1}>
            <img src="/gpdr-icon.svg" className={classes.logo} />
            <Typography color="textPrimary" className={classes.content}>
              {content}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickAccept}
            className={classes.button}>
            I Accept
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default GPDRBanner;
