export default {
  // h1: {
  //   fontSize: "2rem",
  //   fontWeight: 500,
  //   lineHeight: 1.25,
  // },
  // h2: {
  //   fontSize: "1.5rem",
  //   fontWeight: 500,
  //   lineHeight: 1.35,
  // },
  // body1: {
  //   fontSize: "1rem",
  //   lineHeight: 1.5,
  //   fontWeight: 500,
  // },
  // body2: {
  //   fontSize: "1rem",
  //   lineHeight: 1.5,
  //   fontWeight: 400,
  // },
  caption: {
    fontSize: "0.75rem",
    lineHeight: 1,
    fontWeight: 400,
  },
  h1: {
    fontSize: "3.5rem",
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: "2.5rem",
    fontWeight: 600,
    lineHeight: 1.35,
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.35,
  },
  h4: {
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: 1.35,
  },
  body1: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 600,
  },
  body2: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
};
