import { format } from "d3-format";

// TODO: create locales with BTC and ETH instead of this?
export const CURRENCY_TO_SYMBOL = {
  USD: "$",
  BTC: "Ƀ",
  ETH: "Ξ",
};

export function currencify(value, currency) {
  currency = currency.toUpperCase();

  const symbol = CURRENCY_TO_SYMBOL[currency];

  if (symbol === void 0) {
    return value;
  }
  if (currency === "USD") {
    if (value.toString().startsWith("−") === true) {
      return `−${symbol}${value.substring(1)}`;
    } else {
      return symbol + value;
    }
  } else {
    return value + symbol;
  }
}

export const squeezeNumber = (num, decimals = 2, shortenDecimals = 2) => {
  num = parseFloat(num);

  if (num > 1e12) {
    return (num / 1e12).toFixed(shortenDecimals) + "T";
  } else if (num > 1e9) {
    return (num / 1e9).toFixed(shortenDecimals) + "B";
  } else if (num > 1e6) {
    return (num / 1e6).toFixed(shortenDecimals) + "M";
  } else if (num > 1e3) {
    return (num / 1e3).toFixed(shortenDecimals) + "K";
  } else {
    if (decimals !== null) {
      return num.toFixed(decimals);
    } else {
      return num;
    }
  }
};

export const removeDecimalFormat = (oriFormatString, number) => {
  const num = parseFloat(number);
  if (num < 1) {
    return format(oriFormatString)(parseFloat(num));
  } else {
    return format(",d")(parseFloat(num));
  }
};

export const formatNumber = (number, formatNum = ".4s") => {
  let formattedValue = format(formatNum)(number);
  if (/.\dG$/.test(formattedValue)) {
    formattedValue = formattedValue.replace(/G/, "B");
  }
  return formattedValue;
};

export const removeUnderscore = (exchange) => {
  return exchange.replace(/[\s_]/g, " ");
};

export const addUnderscore = (title) => {
  return title && title.replace(/\s*$/, "").split(" ").join("-").toLowerCase();
};

export const addUnderscoreForExchange = (title) => {
  if (title === "huobi-global") {
    return "huobi";
  }
  return title && title.split("-").join("").toLowerCase();
};

export const capitalize = (string, allWords) => {
  return allWords
    ? string
        .split(" ")
        .map((word) => capitalize(word))
        .join(" ")
    : string.charAt(0).toUpperCase() + string.slice(1);
};

export const chunk = (arr) => {
  let groups = [];

  for (let i = 0; i < arr.length; i += 2) {
    groups.push(arr.slice(i, i + 2));
  }

  return groups;
};
