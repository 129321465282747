/* eslint-disable unused-imports/no-unused-vars */
import Router from "next/router";
import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from "react";
import { setUserToken } from "@server-lib/utils/cookie";
import {
  User,
  AuthProviderType,
  AuthContextInterface,
  AuthProviderProps,
} from "./types";

export const AUTH_ERROR_CODE = {
  NEED_VERIFICATION_EMAIL: 1001,
  WRONG_PASSWORD: "auth/wrong-password",
  NOT_FOUND_ACCOUNT: "auth/user-not-found",
};

const useAuthProvider = (u: User) => {
  const [user, setUserInfo] = useState<User | null>(u);
  const [isInitializing, setInitialize] = useState(true);

  const setUser = (u: User | null) =>
    setUserInfo(u === null ? null : { ...user, ...u });

  const authUser = null; // TODO: get auth user

  // TODO: set user type
  const createUser = async (user: any) => {
    // const uData; // TODO: get user
    // const oriData = uData.data();
    // const data: UserData = {
    //   ...defaultUserData,
    //   _id: user.uid,
    //   email: user.email,
    //   firstName:
    //     oriData && !!oriData.firstName
    //       ? oriData.firstName
    //       : user.displayName.split(" ")[0],
    //   lastName:
    //     oriData && !!oriData.lastName
    //       ? oriData.lastName
    //       : user.displayName.split(" ")[1],
    //   avatarUrl:
    //     oriData && !!oriData.avatarUrl ? oriData.avatarUrl : user.photoURL,
    // };
    // TODO: update data and return updated user
  };

  const signIn = async (params: { email: string; password: string }) => {
    return { error: { message: "Forbidden" } };
    // try {
    //   const { email, password } = params;
    //   const response; // TODO: auth with email and password
    //   return await getUserAdditionalData(response.user);
    // } catch (error) {
    //   return { error };
    // }
  };

  const signInWithGoogle = async () => {
    return { error: { message: "Forbidden" } };
    // try {
    //   const result; // TODO: auth with google
    //   await createUser(result.user);
    //   return await getUserAdditionalData(result.user);
    // } catch (error) {
    //   return { error };
    // }
  };

  const signInWithFacebook = async () => {
    return { error: { message: "Forbidden" } };
    // try {
    //   const result; // TODO: auth with facebook
    //   await createUser(result.user);
    //   return await getUserAdditionalData(result.user);
    // } catch (error) {
    //   return { error };
    // }
  };

  const signOut = () => {
    Router.push("/");
    // TODO: sign out
    setUserToken("");
    setUserInfo(null);
  };

  const verifyEmail = async (params: { token: string }) => {
    return { error: { message: "Forbidden" } };
    // try {
    //   const { token } = params;
    //   // TODO: verify email
    //   return {
    //     data: true,
    //   };
    // } catch (error) {
    //   return { error };
    // }
  };

  // forgot password => send email => reset password
  const resetPassword = async (params: { token: string; password: string }) => {
    return { error: { message: "Forbidden" } };
    // try {
    //   const { token, password } = params;
    //   // TODO: verify token and confirm password reset
    //   return {
    //     data: true,
    //   };
    // } catch (error) {
    //   return { error };
    // }
  };

  const changePassword = async (params: {
    oldPassword: string;
    newPassword: string;
  }) => {
    return { error: { message: "Forbidden" } };
    // try {
    //   const { oldPassword, newPassword } = params;
    //   if (authUser) {
    //     if (
    //       oldPassword &&
    //       newPassword &&
    //       oldPassword.length > 0 &&
    //       newPassword.length > 0
    //     ) {
    //       // TODO: auth with old password and update to new
    //     }
    //     return {
    //       data: true,
    //     };
    //   } else {
    //     return {
    //       error: {
    //         message: "The user needs to login",
    //       },
    //     };
    //   }
    // } catch (error) {
    //   return { error };
    // }
  };

  const setNewPassword = async (params: { password: string }) => {
    return { error: { message: "Forbidden" } };
    // try {
    //   const { password } = params;
    //   if (!authUser) {
    //     return {
    //       error: {
    //         message: "You need to login first",
    //       },
    //     };
    //   }
    //   // TODO: set new password
    //   return {
    //     data: true,
    //   };
    // } catch (error) {
    //   return { error };
    // }
  };

  const updateProfile = async (params: {
    firstName: string;
    lastName: string;
  }) => {
    return { error: { message: "Forbidden" } };
    // try {
    //   const { firstName, lastName } = params;
    //   // TODO: update user
    //   return {
    //     data: true,
    //   };
    // } catch (error) {
    //   return { error };
    // }
  };

  const linkSocialMedia = async (type: AuthProviderType) => {
    return { error: { message: "Forbidden" } };
    // if (!authUser) {
    //   return {
    //     error: {
    //       message: "You need to login first",
    //     },
    //   };
    // }
    // try {
    //   let key = "";
    //   if (type === "GOOGLE") {
    //     // TODO: link google
    //     key = "google.com";
    //   } else if (type === "FACEBOOK") {
    //     // TODO: link facebook
    //     key = "facebook.com";
    //   } else if (type === "TWITTER") {
    //     // TODO: link twitter
    //     key = "twitter.com";
    //   }
    //   const providerData = []; // TODO: get provider data
    //   if (providerData.length > 0) {
    //     const pData = providerData[0];
    //     const uData; // TODO: get user data
    //     const oriData = uData.data();
    //     const data = {
    //       firstName:
    //         oriData && !!oriData.firstName
    //           ? oriData.firstName
    //           : pData.displayName.split(" ")[0],
    //       lastName:
    //         oriData && !!oriData.lastName
    //           ? oriData.lastName
    //           : pData.displayName.split(" ")[1],
    //       avatarUrl:
    //         oriData && !!oriData.avatarUrl ? oriData.avatarUrl : pData.photoURL,
    //     };
    //     // TODO: update user data
    //   }
    //   return {
    //     data: true,
    //   };
    // } catch (error) {
    //   return { error };
    // }
  };

  const getUserAdditionalData = async (user: any) => {
    return { error: { message: "Forbidden" } };
    // try {
    //   const { signInProvider } = await user.getIdTokenResult();
    //   if (
    //     (user.emailVerified && signInProvider === "password") ||
    //     signInProvider !== "password"
    //   ) {
    //     const userData; // TODO: get user
    //     if (userData.exists) {
    //       const {
    //         _id,
    //         email,
    //         firstName,
    //         lastName,
    //         avatarUrl,
    //       } = userData.data();
    //       const uData = {
    //         _id,
    //         email,
    //         firstName,
    //         lastName,
    //         avatarUrl,
    //       };
    //       setUserInfo(uData);
    //       return {
    //         data: uData,
    //       };
    //     } else {
    //       return {
    //         error: {
    //           message: "Your profile does not exist",
    //         },
    //       };
    //     }
    //   } else {
    //     signOut();
    //     return {
    //       error: {
    //         code: AUTH_ERROR_CODE.NEED_VERIFICATION_EMAIL,
    //         message: "Please verify your account first",
    //       },
    //     };
    //   }
    // } catch (error) {
    //   return { error };
    // }
  };

  // useEffect(() => {
  //   return auth.onIdTokenChanged(async (user) => {
  //     if (!user) {
  //       setUserInfo(null);
  //       setUserToken("");
  //     } else {
  //       const { signInProvider } = await user.getIdTokenResult();
  //       if (
  //         (user.emailVerified && signInProvider === "password") ||
  //         signInProvider !== "password"
  //       ) {
  //         const token = await user.getIdToken();
  //         setUserToken(token);
  //       }
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   const handleAuthStateChanged = async (user: any) => {
  //     if (user) {
  //       await getUserAdditionalData(user);
  //     } else {
  //       signOut();
  //     }
  //     setInitialize(false);
  //   };

  //   // TODO: subscribe on auth changes and log out when needed
  // }, []);

  // useEffect(() => {
  //   if (authUser) {
  //    // TODO: subscribe on updates of user and use setUser to store data
  //   }
  // }, [authUser]);

  return {
    user,
    setUser,
    signIn,
    signInWithGoogle,
    signInWithFacebook,
    signOut,
    verifyEmail,
    resetPassword,
    changePassword,
    setNewPassword,
    updateProfile,
    linkSocialMedia,
    isInitializing,
  };
};

const AuthContext = createContext<AuthContextInterface>(null);

export const AuthProvider = ({ children, initialUser }: AuthProviderProps) => {
  const provider = useAuthProvider(initialUser);
  const authUser = null; // TODO: get auth user

  const hasAuthProvider = useCallback(
    (type: AuthProviderType) => {
      if (!authUser) return null;
      let key = "";
      switch (type) {
        case "GOOGLE":
          key = "google.com";
          break;
        case "EMAIL_PASSWORD":
          key = "password";
          break;
        case "FACEBOOK":
          key = "facebook.com";
          break;
        // TO DO: twitter
      }
      return (
        authUser.providerData.filter((t) => t.providerId === key).length > 0
      );
    },
    [authUser],
  );

  useEffect(() => {
    if (initialUser) provider.setUser(initialUser);
  }, [initialUser]);

  return (
    <AuthContext.Provider
      value={{
        ...provider,
        isInitializing: false, // TODO: use it from provider
        authUser,
        hasAuthProvider,
        isAuthenticated: !!authUser,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
