import { useState, useCallback, useEffect, useMemo, Fragment } from "react";
import cx from "classnames";
import NextLink from "next/link";
import Router, { useRouter, NextRouter } from "next/router";

import { Routes } from "@lib/routes";
import AuthLink from "@components/AuthLink";
import {
  IconButton,
  Button,
  AppBar,
  Toolbar,
  Box,
  Link,
  Avatar,
  ButtonBase,
  Typography,
  LinearProgress,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "@lib/themes";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbars } from "@components/use-snackbar";
import { Menu, MenuItem } from "@components/Menu";
import { ITheme } from "@lib/themes/types";
import {
  getNavigation,
  NavigationItem,
  ParentNavigationItem,
} from "@lib/const";
import { useAuth } from "@components/app";
import fetcher from "@lib/fetcher";
import useSWR from "swr";
import ExpandMore from "@public/expand-more.svg";
import ThemeSwitch from "@components/ThemeSwitch";

import { useAppTheme } from "./use-app-theme";
import { MobileMenu } from "./mobile-menu";
import { DataHeader } from "./data-header";
import { Search } from "./search";
import UserIcon from "@public/user-icon.svg";
import trackEvent, {
  GAEventAction,
  GAEventCategory,
  GAEvent,
  GAEventLabel,
} from "@lib/ga";

const useStyles = makeStyles((theme: ITheme, props) => ({
  toolbar: {
    height: 88,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      width: "100%",
      maxWidth: 1360,
      marginLeft: "auto",
      marginRight: "auto",
    },
    "@media(min-width: 1400px)": {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      maxWidth: 1440,
    },
  },
  appbar: {
    zIndex: 1201,
    position: props.hideHeader || props.notStickyHeader ? "relative" : "sticky",
    top: 0,
    boxShadow: "none",
    background: theme.palette.header.background,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.header.secondBackground,
    filter: "drop-shadow(0px 4px 40px rgba(255, 255, 255, 0.04))",
    paddingRight: "0px !important",
    [theme.breakpoints.up("md")]: {
      height: props.hideHeader ? 88 : 136,
    },
  },
  logo: {
    position: "relative",
    cursor: "pointer",
    height: 40,
    // "& svg": {
    //   width: 64,
    //   height: 24,
    //   "& > path": {
    //     fill: theme.palette.text.primary,
    //   },
    // },
  },
  avatarWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(3),
    "& svg": {
      "& > path": {
        fill: theme.palette.text.primary,
      },
    },
  },
  avatar: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.avatarBackground,
  },
  navContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      marginLeft: theme.spacing(2),
      "&._search-opened": {
        "._icon": {
          marginRight: "0 !important",
        },
        "._label": {
          display: "none !important",
        },
      },
    },
    [theme.breakpoints.only("lg")]: {
      marginLeft: theme.spacing(3),
    },
    "@media(min-width: 1400px)": {
      marginLeft: theme.spacing(7.5),
    },
  },
  navItem: {
    position: "relative",
    textTransform: "none",
    marginRight: theme.spacing(2),
    borderRadius: 8,
    [theme.breakpoints.only("md")]: {
      marginRight: 0,
    },
    [theme.breakpoints.only("lg")]: {
      marginRight: theme.spacing(1),
    },
    "&:last-child": {
      marginRight: 0,
    },
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      background: theme.palette.navItem.backgroundHover,
      color: theme.palette.text.primary,
    },
    "&._selected": {
      color: "#fff",
      background: theme.palette.navItem.backgroundSelected,

      "&:hover": {
        background: theme.palette.navItem.backgroundSelectedHover,
      },

      [theme.breakpoints.only("md")]: {
        "._icon": {
          marginRight: theme.spacing(1),
        },
        "._label": {
          display: "block",
        },
      },
    },
    padding: "8px 12px",
    ".MuiButton-endIcon svg": {
      path: {
        fill: "currentColor",
      },
    },
    // "& div[view-type='icon']": {
    //   'red',
    //   boxShadow: theme.palette.headerMenu.shadow,
    //   "& path": {
    //     fill: theme.palette.headerMenu.icon,
    //   },
    // },
    // "&:hover": {
    //   "& p": {
    //     color: theme.palette.primary.main,
    //   },
    //   "& .MuiButton-endIcon": {
    //     "& path": {
    //       fill: theme.palette.primary.main,
    //     },
    //   },
    //   "& div[view-type='icon']": {
    //     backgroundColor: theme.palette.primary.main,
    //     boxShadow: theme.palette.headerMenu.shadowHover,
    //     "& path": {
    //       fill: theme.palette.headerMenu.iconHover,
    //     },
    //   },
    // },
    // "&._opened": {
    //   "& p": {
    //     color: theme.palette.primary.main,
    //   },
    //   "& .MuiButton-endIcon": {
    //     "& path": {
    //       fill: theme.palette.primary.main,
    //     },
    //   },
    //   "& div[view-type='icon']": {
    //     backgroundColor: theme.palette.primary.main,
    //     boxShadow: theme.palette.headerMenu.shadowHover,
    //     "& path": {
    //       fill: theme.palette.headerMenu.iconHover,
    //     },
    //   },
    // },
  },
  navItemLabel: {
    [theme.breakpoints.only("md")]: {
      display: "none",
    },
  },
  navItemFolded: {
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  activeItemBg: {
    display: "flex",
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  endContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  search: {
    marginLeft: 0,

    "& svg > g > path ": {
      fill: theme.palette.text.primary,
    },

    [theme.breakpoints.up("md")]: {
      width: 212,
    },
  },
  navItemIcon: {
    // padding: theme.spacing(1),
    // borderRadius: "50%",
    // backgroundColor: 'red',
    [theme.breakpoints.only("md")]: {
      marginRight: 0,
    },
  },
  loader: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    opacity: 0,
    pointerEvents: "none",
  },
  iconButton: {
    "& svg path ": {
      fill: theme.palette.text.primary,
    },
    "&:hover": {
      background:
        "linear-gradient(273.74deg, rgba(4, 137, 163, 0.24) 3.07%, rgba(116, 209, 227, 0.24) 96.93%)",
    },
  },
  loginLinkWrap: {
    "@media(max-width: 1399px)": {
      marginRight: theme.spacing(1),
    },
    "@media(min-width: 1400px)": {
      marginRight: theme.spacing(2),
    },
  },
  loginLink: {
    display: "flex",
    alignItems: "center",
    span: {
      "@media(max-width: 1399px)": {
        display: "none",
      },
    },
  },
  userName: {
    maxWidth: 80,
    "@media(max-width: 1399px)": {
      display: "none",
    },
  },
}));

export function DashboardHeader({
  initialHeader,
  hideHeader = false,
  notStickyHeader = false,
}) {
  const classes = useStyles({ hideHeader, notStickyHeader });
  const router = useRouter();
  const { theme } = useAppTheme();
  const { user, signOut, isInitializing, isAuthenticated } = useAuth();
  const [header, setHeader] = useState(initialHeader);
  const upMdMatch = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpened, setMobileMenuOpenned] = useState(false);
  const [searchShown, setSearchShown] = useState(false);
  // const [isDarkMode, setIsDarkMode] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useSWR("/api/header", fetcher, {
    onSuccess: (data) => {
      setHeader(data);
    },
  });

  // TODO: place it somewhere else
  // useEffect(() => {
  //   setIsDarkMode(theme.palette.type !== "light")
  // }, [theme.palette.type])

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpenned(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpenned((prevState) => {
      if (!prevState) {
        trackEvent(
          {
            action: GAEventAction.OpenMenu,
            category: GAEventCategory.Header,
            label: GAEventLabel.OpenMenu,
          },
          isMobile,
        );
      }
      return !prevState;
    });
  };

  const openSearch = () => {
    if (!isAuthenticated) {
      Router.push(Routes.Login);
      return;
    }
    trackEvent(
      {
        action: GAEventAction.Search,
        category: GAEventCategory.Header,
        label: GAEventLabel.OpenSearch,
      },
      isMobile,
    );
    setSearchShown(true);
  };

  const closeSearch = useCallback(() => {
    setSearchShown(false);
  }, []);

  const { createSnackbar } = useSnackbars();

  const onLogoutClick = () => {
    handleUserMenuClose();
    signOut();
    createSnackbar("Successful logout", "success");
  };

  const renderSearchInput = () => {
    return <Search onClear={closeSearch} />;
  };

  useEffect(() => {
    closeSearch();
  }, [router.pathname, router.asPath]);

  return (
    <AppBar position="fixed" classes={{ root: classes.appbar }}>
      <Toolbar classes={{ root: classes.toolbar }} disableGutters={true}>
        {searchShown && !upMdMatch ? (
          renderSearchInput()
        ) : (
          <NextLink href="/">
            <img
              src="/logo.png"
              className={classes.logo}
              onClick={() =>
                trackEvent(
                  {
                    action: GAEventAction.Logo,
                    category: GAEventCategory.Header,
                    label: GAEventLabel.Logo,
                  },
                  isMobile,
                )
              }
            />
          </NextLink>
        )}
        <div
          className={`${classes.navContainer} ${
            searchShown ? "_search-opened" : ""
          }`}>
          {getNavigation({ isMobile: true, isAuthenticated }).map((v) => (
            // <Box className={`${pathCheck(v) ? classes.selected : ""}`}>
            <NavItem
              themeType={theme.palette.mode}
              router={router}
              {...v}
              key={v.name}
              hideHeader={hideHeader}
            />
            // </Box>
          ))}
        </div>
        <div className={classes.endContainer}>
          {upMdMatch &&
            (!searchShown ? (
              <IconButton
                color="default"
                aria-label="search"
                className={classes.iconButton}
                onClick={openSearch}>
                <SearchIcon />
              </IconButton>
            ) : (
              renderSearchInput()
            ))}
          {/* {renderSearchInput()} */}
          {isInitializing ? (
            <Box mr={5} ml={3}>
              <Skeleton variant="circular">
                <Avatar className={classes.avatar} />
              </Skeleton>
            </Box>
          ) : user ? (
            <>
              <ButtonBase
                focusRipple
                className={classes.avatarWrapper}
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}>
                {user.avatarUrl && (
                  <Box pr={1}>
                    <Avatar alt="User" src={user.avatarUrl} />
                  </Box>
                )}
                <Typography
                  variant="inherit"
                  noWrap
                  className={classes.userName}>
                  {user.firstName && user.firstName.length > 0
                    ? user.firstName
                    : user.email}
                </Typography>
                <ExpandMore />
              </ButtonBase>
              <Menu
                id="user-menu"
                style={{
                  zIndex: theme.zIndex.modal + 1,
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleUserMenuClose}>
                <NextLink href="/account">
                  <Link
                    color="inherit"
                    variant="body2"
                    underline="none"
                    href="/account">
                    <MenuItem onClick={handleUserMenuClose}>
                      Account Data
                    </MenuItem>
                  </Link>
                </NextLink>
                <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <Box display="flex" className={classes.loginLinkWrap}>
              <NextLink href={Routes.Login} passHref={true}>
                <Link
                  color="inherit"
                  variant="body1"
                  className={classes.loginLink}
                  onClick={() =>
                    trackEvent({
                      action: GAEventAction.Account,
                      category: GAEventCategory.Header,
                      label: GAEventLabel.SignIn,
                    })
                  }>
                  <IconButton>
                    <UserIcon />
                  </IconButton>
                  <span>Login</span>
                </Link>
              </NextLink>
            </Box>
          )}
          <ThemeSwitch />
        </div>
        {!upMdMatch && (
          <div>
            {!searchShown && (
              <IconButton
                color="default"
                aria-label="search"
                className={classes.iconButton}
                onClick={openSearch}>
                <SearchIcon />
              </IconButton>
            )}
            <IconButton
              color="default"
              aria-label="menu"
              className={classes.iconButton}
              onClick={toggleMobileMenu}>
              {mobileMenuOpened ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </div>
        )}
      </Toolbar>
      {!hideHeader &&
        (header ? <DataHeader header={header} /> : <LinearProgress />)}
      {!upMdMatch && (
        <MobileMenu
          opened={mobileMenuOpened}
          close={closeMobileMenu}
          onLogout={onLogoutClick}
        />
      )}
    </AppBar>
  );
}

function NavItem({
  name,
  Icon,
  path,
  auth = false,
  items,
  router,
  themeType,
  hideHeader,
  gaEvent,
}: {
  name: string;
  Icon?: React.ElementType;
  path?: string;
  auth?: boolean;
  items?: (NavigationItem | ParentNavigationItem)[];
  router: NextRouter;
  themeType: "light" | "dark";
  hideHeader?: boolean;
  gaEvent?: GAEvent;
}) {
  const classes = useStyles({ hideHeader });
  const [anchor, setAnchor] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    let selected = false;
    if (items !== void 0) {
      selected = items.some((v) => router.pathname === v.path);
      // for only labs path
      if (name === "Labs" && router.pathname === "/labs") {
        selected = true;
      }
    } else {
      selected = router.pathname === path;
    }
    setIsSelected(selected);
  }, [router.pathname]);

  const base = useMemo(() => {
    return (
      <Box alignItems="center" display="flex">
        {Icon && (
          <Box
            className={`_icon ${classes.navItemIcon}`}
            mr={1}
            view-type="icon">
            <Box
              width={24}
              height={24}
              display="flex"
              justifyContent="center"
              alignItems="center">
              <Icon themeType={themeType} selected={isSelected} />
            </Box>
          </Box>
        )}
        <Typography
          variant="body2"
          className={`_label ${classes.navItemLabel}`}>
          {name}
        </Typography>
      </Box>
    );
  }, [Icon, name, items, themeType, router.pathname, isSelected]);

  if (items === void 0) {
    return (
      <AuthLink href={path} key={name} auth={auth} prefetch={false}>
        <Link
          className={cx(classes.navItem, {
            // _opened: anchor !== null,
            _selected: isSelected,
          })}
          color="inherit"
          variant="body2"
          href={path}
          onClick={() => trackEvent(gaEvent)}>
          {base}
        </Link>
      </AuthLink>
    );
  } else {
    const onClose = () => setAnchor(null);
    return (
      <Fragment key={name}>
        <Button
          className={cx(classes.navItem, {
            // _opened: anchor !== null,
            _selected: isSelected,
          })}
          endIcon={<ExpandMore />}
          color="inherit"
          onClick={(e) => {
            setAnchor(e.currentTarget);
            trackEvent({
              action: name,
              category: GAEventCategory.Header,
              label: items.map((i) => i.name).join(" \n"),
            });
          }}>
          {base}
        </Button>

        <Menu
          minWidth={193}
          keepMounted={true}
          anchorEl={anchor}
          open={anchor !== null}
          // disablePortal={true}
          onSelect={() => setAnchor(null)}
          onClose={onClose}
          PaperProps={{
            style: {
              maxHeight: "none",
            },
          }}>
          {items.map((v) => (
            <AuthLink href={v.path} key={v.name} auth={v.auth} prefetch={false}>
              <Link
                className={classes.navItemFolded}
                color="inherit"
                variant="body2"
                underline="none"
                href={v.path}>
                <MenuItem
                  key={v.name}
                  onClick={() => {
                    onClose();
                    trackEvent(v.gaEvent);
                  }}
                  selected={router.pathname === v.path}>
                  {v.name}
                </MenuItem>
              </Link>
            </AuthLink>
          ))}
        </Menu>
      </Fragment>
    );
  }
}
