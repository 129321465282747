import { GetServerSidePropsContext } from "next";
import { NextRequest } from "next/server";

import nookies from "nookies";

const setCookie = (key: string, value: string) => {
  nookies.set(undefined, key, value, { path: "/" });
};

const getCookie = (context: GetServerSidePropsContext) => {
  return nookies.get(context);
};

const userTokenName = "__tloukneenr";

const setUserToken = (token: string) => {
  setCookie(userTokenName, token);
};

const getUserTokenFromReq = (
  req: GetServerSidePropsContext["req"] | NextRequest,
) => {
  const { cookies } = req;
  if ("get" in cookies && typeof cookies.get === "function") {
    return cookies.get(userTokenName);
  } else {
    return req.cookies[userTokenName];
  }
};

const getUserToken = (context: GetServerSidePropsContext) => {
  // const cookie = getCookie(context);
  // return cookie[userTokenName];
  return getUserTokenFromReq(context.req);
};

export {
  setCookie,
  getCookie,
  setUserToken,
  getUserToken,
  getUserTokenFromReq,
};
