import { Input, Options } from "ky";

interface Error {
  error: string;
}

export default async function fetcher<T>(
  url: Input,
  options?: Options,
): Promise<T> {
  const ky = (await import("ky-universal")).default;

  try {
    const resp: T = await ky(url, { timeout: 60000, ...options }).json();
    return resp;
  } catch (err) {
    const resp: Error = await err.response.json();
    throw resp;
  }
}
