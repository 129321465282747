import cx from "classnames";
import { useRouter } from "next/router";
import { Container, useMediaQuery, useTheme, Box } from "@mui/material";

import { Routes } from "@lib/routes";
import { makeStyles } from "@lib/themes";

import TopButton from "../TopButton";

import { DashboardHeader } from "./dashboard-header";
import { Footer } from "./footer";
import GPDRBanner from "./gpdr-banner";

const useStyles = makeStyles((theme, props) => ({
  container: {
    paddingTop: props.isTerminal ? theme.spacing(0) : theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: props.isTerminal ? theme.spacing(2) : theme.spacing(10),

    [theme.breakpoints.up("md")]: {
      paddingTop: props.isTerminal ? theme.spacing(0) : theme.spacing(3),
      paddingRight: props.isTerminal ? theme.spacing(3) : theme.spacing(10),
      paddingLeft: props.isTerminal ? theme.spacing(3) : theme.spacing(10),
      paddingBottom: props.isTerminal ? theme.spacing(3) : theme.spacing(8),
    },
  },
  largeTop: {
    marginTop: theme.spacing(10),
  },
  root: {
    minHeight: props.isTerminal ? "100vh" : "calc(100vh - 276px)",
    position: "relative",
    maxWidth: props.fullWidthLayout ? "" : 1440,
    marginLeft: props.fullWidthLayout ? "" : "auto",
    marginRight: props.fullWidthLayout ? "" : "auto",
    // backdropFilter: "blur(608px)",
    // overflowY: "scroll",
  },
  wrapper: {
    background: `url(/bg-desktop-${theme.palette.mode}.png) repeat-y ${theme.palette.background.secondBackground} center top`,
    backgroundSize: "auto 1600px",
    // background: props.isTerminal
    //   ? theme.palette.mode === "dark"
    //     ? "#052435"
    //     : "#FAFFFF"
    //   : theme.palette.background.main,
    // backgroundColor: props.isTerminal
    //   ? theme.palette.mode === "dark"
    //     ? "#052435"
    //     : "#FAFFFF"
    //   : theme.palette.background.secondBackground,
  },
}));

const paths = ["asset", "exchange", "dexes", "login"];
const hideAllHeader = [
  Routes.Login,
  "/forgot-password",
  "/signup",
  "/signup-finish",
  "/reset-password",
  "/terminal",
];

export function PageLayout({ children, pageProps, largeMargin = false }) {
  const router = useRouter();
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles({
    isTerminal: router.pathname === "/terminal",
    fullWidthLayout:
      router.pathname === "/terminal" ||
      router.pathname === Routes.Login ||
      router.pathname === "/signup" ||
      router.pathname === "/forgot-password" ||
      router.pathname === "/reset-password",
  });

  return (
    <>
      {!hideAllHeader.some((v) => router.pathname === v && !isMobile) && (
        <DashboardHeader
          hideHeader={
            paths.some((v) => {
              const url = router.pathname.split("/");
              return url[1] === v && url.length > 2;
            }) ||
            (router.pathname === "/labs/correlation" && isMobile) ||
            router.pathname === "/portfolio" ||
            router.pathname === "/defi" ||
            router.pathname === "/labs/labs-comparison" ||
            hideAllHeader.some((v) => router.pathname === v)
          }
          notStickyHeader={
            router.pathname === "/" || router.pathname === "/liquidity"
          }
          initialHeader={pageProps?.json?.app?.header ?? null}
        />
      )}
      <Box className={classes.wrapper}>
        <main className={classes.root}>
          <Container
            maxWidth={false}
            classes={{
              root: cx(classes.container, {
                [classes.largeTop]: largeMargin,
              }),
            }}
            disableGutters={true}>
            {children}
          </Container>
        </main>
        {!hideAllHeader.some((v) => router.pathname === v) && <Footer />}
      </Box>

      <GPDRBanner />

      <TopButton />
    </>
  );
}

export const getPageLayout = (Page, pageProps?, props?, err?) => (
  <PageLayout {...props} pageProps={pageProps}>
    <Page {...pageProps} err={err} />
  </PageLayout>
);
