import Typography, { TypographyProps } from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { useTheme } from "@mui/material";
import React, { useMemo } from "react";
import Image from "next/image";

type NumberDynamicProps = {
  dynamic: number;
  children: React.ReactNode;
  boxProps?: BoxProps;
  hideArrow?: boolean;
  typographyProps?: TypographyProps;
};

// dynamic: 0 - no dynamic, 1 up, -1 down
export default function NumberDynamic({
  dynamic,
  children,
  boxProps,
  hideArrow,
  typographyProps,
}: NumberDynamicProps) {
  const theme = useTheme();

  const data = useMemo(() => {
    if (dynamic < 0) {
      return {
        dynamic: -1,
        color: theme.palette.error.main,
      };
    } else if (dynamic > 0) {
      return {
        dynamic: 1,
        color: theme.palette.success.main,
      };
    } else {
      return {
        dynamic: undefined,
        color: theme.palette.success.main,
      };
    }
  }, [dynamic]);

  return (
    <Box
      color={data.color}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      {...boxProps}>
      {!hideArrow && data.dynamic && (
        <Box
          width={24}
          height={24}
          minWidth={24}
          minHeight={24}
          className="arrow-image">
          <Image
            width={24}
            height={24}
            src={data.dynamic === 1 ? "/up.svg" : "/down.svg"}
            alt={data.dynamic === 1 ? "up arrow" : "down arrow"}
          />
        </Box>
      )}
      <Typography variant="body1" {...typographyProps}>
        {children}
      </Typography>
    </Box>
  );
}
