import React from "react";
import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import { AppProps } from "next/app";
import { NextComponentType } from "next";
import Head from "next/head";
import { TssCacheProvider } from "tss-react";
import { AppCacheProvider } from "@mui/material-nextjs/v13-pagesRouter";
import { createEmotionSsrAdvancedApproach } from "tss-react/next/pagesDir";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "@components/use-snackbar";
import { getPageLayout, AppThemeProvider, AuthProvider } from "@components/app";
import { CacheProvider } from "@emotion/react";
import { muiCache } from "@lib/themes";

type PageComponent = NextComponentType & {
  getLayout?: (
    Page: React.ComponentType,
    pageProps?,
    props?,
    err?: Error,
  ) => React.ReactNode;
};

// import { Theme } from "@mui/material/styles";

// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
//   interface DefaultTheme extends Theme {}
// }

function MyApp(props: AppProps) {
  const { Component, pageProps } = props;
  const AppComponent: PageComponent = Component;
  const getLayout = AppComponent.getLayout || getPageLayout;
  const initialUser = null; //pageProps?.initialUser || null; // TODO: fix it

  // React.useEffect(() => {
  //   // Remove the server-side injected CSS.
  //   // TODO: no sure if this has any effect with MUIv4
  //   const jssStyles = document.querySelector("#jss-server-side");
  //   if (jssStyles) {
  //     jssStyles.parentElement.removeChild(jssStyles);
  //   }
  // }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicon-16x16.png"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="manifest" href="/static/site.webmanifest" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Head>
      <AppCacheProvider {...props}>
        <CacheProvider value={muiCache}>
          <AppThemeProvider>
            <CssBaseline />
            <AuthProvider initialUser={initialUser}>
              <SnackbarProvider>
                {getLayout(Component, pageProps)}
              </SnackbarProvider>
            </AuthProvider>
          </AppThemeProvider>
        </CacheProvider>
      </AppCacheProvider>
    </>
  );
}

const {
  augmentDocumentWithEmotionCache: augmentDocumentWithEmotionCache_mui,
  withAppEmotionCache: withAppEmotionCache_mui,
} = createEmotionSsrAdvancedApproach({ key: "mui", prepend: true });

const {
  augmentDocumentWithEmotionCache: augmentDocumentWithEmotionCache_tss,
  withAppEmotionCache: withAppEmotionCache_tss,
} = createEmotionSsrAdvancedApproach({ key: "tss" }, TssCacheProvider);

export {
  augmentDocumentWithEmotionCache_mui,
  augmentDocumentWithEmotionCache_tss,
};

export default withAppEmotionCache_mui(withAppEmotionCache_tss(MyApp));
