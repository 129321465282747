import {
  getValueForKeyInBrowserStorage,
  storeInBrowserStorage,
} from "@lib/utils/browser-storage";

export const useStorage = () => {
  const toStorage = (name, value) => {
    storeInBrowserStorage(name, value);
  };

  const getStorage = (name) => {
    return getValueForKeyInBrowserStorage(name);
  };

  return {
    toStorage,
    getStorage,
  };
};
