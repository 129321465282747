import React, { useMemo } from "react";
import {
  FilledInputProps,
  TextField as MaterialTextField,
  InputAdornment,
  TextFieldProps,
  Box,
  BoxProps,
  Typography,
} from "@mui/material";
import { makeStyles } from "@lib/themes";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import VisibilityOff from "@public/visibility-off.svg";
import Visibility from "@public/visibility.svg";
import { ITheme } from "@lib/themes/types";

export interface TextFieldElementProps extends Omit<TextFieldProps, "name"> {
  InputProps?: FilledInputProps;
  disabled?: boolean;
  formLabel?: boolean;
  errorMessage?: string;
  boxProps?: BoxProps;
  maxWidth?: string;
  showErrorMessage?: boolean;
}

const useIconClasses = makeStyles((theme: ITheme) => ({
  root: {
    color: theme.palette.gray.main,
    cursor: "pointer",
  },
}));

const useInputStyles = makeStyles((theme: ITheme) => ({
  root: {
    // padding: "11px 16px !important",
    transition: "none",
    color: theme.palette.input.main.color,
    // backgroundColor: theme.palette.input.main.background,
    // borderRadius: 8,
    // border: `1px solid ${theme.palette.input.main.border}`,
    background: theme.palette.input.main.background,
    borderRadius: "8px 8px 0px 0px",
    borderBottom: `2px solid ${theme.palette.input.main.border}`,
    padding: "12px 19px 10px 12px !important",

    "&:hover:not(.Mui-disabled):not(.Mui-focused)": {
      background: `${theme.palette.input.hover.background} !important`,
      borderColor: `${theme.palette.input.hover.border} `,
      "& .MuiInputBase-input": {
        WebkitBoxShadow: `0 0 0 100px ${theme.palette.input.hover.background} inset`,
      },
    },
    "&.Mui-focused": {
      background: `${theme.palette.input.selected.background} !important`,
      borderColor: `${theme.palette.input.selected.border} `,
      "& .MuiInputBase-input": {
        WebkitBoxShadow: `0 0 0 100px ${theme.palette.input.selected.background} inset`,
      },
    },
    "&.Mui-disabled": {
      userSelect: "none",
      opacity: 0.3,
      backgroundColor: `${theme.palette.input.disabled.background} !important`,
      borderColor: `${theme.palette.input.disabled.border} `,
      color: theme.palette.input.disabled.color,
      "& .MuiInputBase-input": {
        WebkitBoxShadow: `0 0 0 100px ${theme.palette.input.disabled.background} inset`,
      },
    },
  },
  focused: {},
  error: {
    borderColor: ` ${theme.palette.error.main} !important`,
  },
  input: {
    padding: `0 !important`,
    lineHeight: "24px",
    height: 24,
    "&:-webkit-autofill": {
      WebkitBoxShadow: `0 0 0 100px ${theme.palette.input.main.background} inset`,
      WebkitTextFillColor: theme.palette.input.main.color,
      borderRadius: 8,
    },
  },
}));

const useLabelStyles = makeStyles((theme: ITheme, props) => ({
  root: {
    color: theme.palette.input.main.color,
    opacity: 0.3,
    lineHeight: "24px",
    transform: `translate(${props.hasIcon ? 40 : 12}px, 12px) !important`,
    "&.MuiFormLabel-filled": {
      display: "none",
    },
    "&$focused": {
      color: theme.palette.input.main.color,
    },
    "& .Mui-disabled": {
      color: theme.palette.input.disabled.color,
      opacity: 1,
    },
  },
  focused: {},
  error: {
    color: `${theme.palette.error.main} !important`,
    opacity: 1,
  },
  asterisk: {
    display: "none",
  },
}));

const useErrorStyles = makeStyles((theme) => ({
  root: {
    color: `${theme.palette.error.main} !important`,
    margin: 0,
  },
  focused: {},
}));

const useAdornmentStyles = makeStyles(() => ({
  positionEnd: {
    marginRight: -12,
  },
}));

export default function TextField({
  type,
  InputLabelProps,
  FormHelperTextProps,
  InputProps,
  disabled,
  label,
  placeholder,
  formLabel,
  errorMessage,
  helperText,
  boxProps,
  maxWidth,
  showErrorMessage,
  ...rest
}: TextFieldElementProps) {
  const [showPassword, setShowPassword] = React.useState(false);

  const iconClasses = useIconClasses();
  const inputClasses = useInputStyles();
  const labelClasses = useLabelStyles({
    hasIcon: InputProps?.startAdornment !== undefined,
  });
  const errorClasses = useErrorStyles();
  const adornmentStyles = useAdornmentStyles();

  const inputProps = useMemo<FilledInputProps>(() => {
    const data = {
      ...InputProps,
      classes: inputClasses,
      disableUnderline: true,
      disabled,
    };
    if (type === "password") {
      data.endAdornment = (
        <InputAdornment position="end" classes={adornmentStyles}>
          <Box
            aria-label="toggle password visibility"
            className={iconClasses.root}
            onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </Box>
        </InputAdornment>
      );
    }
    return data;
  }, [
    InputProps,
    disabled,
    inputClasses,
    type,
    showPassword,
    adornmentStyles,
    iconClasses,
  ]);

  return (
    <Box {...boxProps}>
      {formLabel && label && (
        <Box mb={1}>
          <Typography variant="body1" color="textPrimary">
            {label}
          </Typography>
        </Box>
      )}
      <MaterialTextField
        variant="filled"
        type={type === "password" && showPassword ? "text" : type}
        error={!!errorMessage}
        helperText={showErrorMessage && (errorMessage || helperText)}
        InputLabelProps={{
          classes: labelClasses,
          shrink: false,
          ...InputLabelProps,
        }}
        FormHelperTextProps={{
          classes: errorClasses,
          ...FormHelperTextProps,
        }}
        InputProps={inputProps}
        disabled={disabled}
        label={placeholder || label}
        {...rest}
      />
    </Box>
  );
}
