import React, { useEffect } from "react";

import { AlertColor, Box, Slide, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded";
import CloseRounded from "@mui/icons-material/CloseRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

interface SnackbarAlertProps {
  onClose?: () => void;
  severity?: AlertColor;
  title: string;
  open: boolean;
}

const useStyles = makeStyles((theme: ITheme, props) => ({
  root: {
    position: "fixed",
    top: 56,
    right: 56,
    zIndex: 9999,
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 400,
    background: theme.palette[props.severity].background,
    border: `1px solid ${theme.palette[props.severity].border}`,
    backdropFilter: "blur(10px)",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      right: 10,
      width: "calc(100% - 20px)",
    },
  },
  icon: {
    fontSize: 16,
    display: "flex",
    "& path": {
      fill: theme.palette[props.severity].border || "",
    },
  },
  alertIcon: {
    marginRight: 10,
    display: "flex",
    "& svg": {
      fill: theme.palette[props.severity].border || "",
      fontSize: 22,
    },
  },
}));

const alerts = {
  error: {
    icon: <WarningAmberRounded />,
    name: "errorSnackbar",
  },
  info: {
    icon: <InfoOutlinedIcon />,
    name: "infoSnackbar",
  },
  success: {
    icon: <CheckCircleOutlineRoundedIcon />,
    name: "successSnackbar",
  },
  warning: {
    icon: <WarningAmberRounded />,
    name: "warningSnackbar",
  },
};

const AUTO_DISMISS = 5000;

const SnackbarAlert = ({
  severity,
  onClose,
  title,
  open,
}: SnackbarAlertProps) => {
  const theme: ITheme = useTheme();

  const classes = useStyles({
    severity: alerts[severity].name,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open) {
        onClose();
      }
    }, AUTO_DISMISS);
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Box className={classes.root}>
        <Box display="flex" alignItems="center">
          <Box className={classes.alertIcon}>{alerts[severity]?.icon}</Box>
          <Typography variant="body2">{title}</Typography>
        </Box>

        <Box>
          <CloseRounded className={classes.icon} onClick={onClose} />
        </Box>
      </Box>
    </Slide>
  );
};

export default SnackbarAlert;
