import cx from "classnames";
import { useMemo } from "react";
import NextLink from "next/link";
import {
  Typography,
  Paper,
  Box,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { makeStyles } from "@lib/themes";

import AuthLink from "@components/AuthLink";
import { ITheme } from "@lib/themes/types";
import {
  isParentNavigationItem,
  getNavigation,
  NavigationItem,
  ParentNavigationItem,
} from "@lib/const";
import { useAuth } from "@components/app";
import trackEvent, {
  GAEventAction,
  GAEventCategory,
  GAEventLabel,
} from "@lib/ga";

import FooterPattern from "../FooterPattern";

const useStyles = makeStyles((theme: ITheme) => ({
  content: {
    maxWidth: 1280,
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  root: {
    position: "relative",
    padding: "24px 16px 40px",
    overflow: "hidden",
    // display: "flex",
    // flexDirection: "column",
    backgroundColor: "transparent",
    boxShadow: "none",
    backgroundImage: "none",
    [theme.breakpoints.up("md")]: {
      // flexDirection: "row",
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      paddingBottom: theme.spacing(3),
    },
  },
  logo: {
    objectFit: "contain",
    objectPosition: "center left",
    position: "relative",
    marginBottom: theme.spacing(5),
    height: 48,
    // "& svg": {
    //   height: 48,
    //   width: "auto",
    //   "& > path": {
    //     fill: theme.palette.footer.logo,
    //   },
    // },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: theme.spacing(3),
      height: 72,
      "& svg": {
        height: 72,
      },
    },
  },
  navItem: {
    marginBottom: theme.spacing(1),
    color: theme.palette.footer.link,
    cursor: "pointer",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  subNavItem: {
    color: theme.palette.footer.text,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
      "&:last-child": {
        marginBottom: theme.spacing(2),
      },
    },
  },
  linkHeader: {
    color: theme.palette.footer.link,
    marginBottom: theme.spacing(1),
  },
  footerNav: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  parentFooterNav: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      // flexDirection: "row-reverse",
      width: "100%",
    },
  },
  footerInfo: {
    display: "flex",
    minWidth: "fit-content",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(20),
      marginBottom: 0,
    },
  },
  parentFooterNavItems: {
    marginRight: theme.spacing(14),
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      flexBasis: "50%",
    },
  },
}));

export function Footer() {
  const classes = useStyles();
  const { user, isAuthenticated } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { navigation, parentNavigation } = useMemo(() => {
    const allNavigation = getNavigation({ isAuthenticated });
    const parentNavigation: ParentNavigationItem[] = [];
    const navigation: NavigationItem[] = [];

    allNavigation.forEach((item) => {
      if (isParentNavigationItem(item)) {
        parentNavigation.push(item as ParentNavigationItem);
      } else {
        navigation.push(item as NavigationItem);
      }
    });
    return {
      navigation,
      parentNavigation,
    };
  }, []);

  return (
    <Paper square className={classes.root}>
      <FooterPattern />
      <Box className={classes.content}>
        <Box className={classes.footerInfo} zIndex={3}>
          <img className={classes.logo} src="/logo.png" />
          <Box zIndex={1}>
            <NextLink href="mailto:info@luner.com">
              <Typography
                variant="body1"
                className={classes.navItem}
                onClick={() =>
                  trackEvent(
                    {
                      action: GAEventAction.InfoEmail,
                      category: GAEventCategory.Footer,
                      label: GAEventLabel.Email,
                    },
                    isMobile,
                  )
                }>
                info@luner.com
              </Typography>
            </NextLink>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.copyright}>
              Copyright © 2021 Luner.com <br />
              All Rights Reserved.
            </Typography>
          </Box>
        </Box>

        <Box className={classes.footerNav} zIndex={3}>
          <Box className={classes.footerNav} zIndex={2}>
            <Box className={classes.parentFooterNav}>
              {parentNavigation.map((item) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  key={item.name}
                  className={classes.parentFooterNavItems}>
                  {item.path && (
                    <AuthLink
                      href={item.path}
                      key={item.name}
                      auth={item.auth}
                      prefetch={false}>
                      <Link
                        className={cx(
                          classes.navItem,
                          classes.subNavItem,
                          classes.linkHeader,
                        )}
                        color="inherit"
                        variant="body1"
                        href={item.path}>
                        {item.name}
                      </Link>
                    </AuthLink>
                    // <Typography variant="body1" className={classes.linkHeader}>
                    //   {item.name}
                    // </Typography>
                  )}
                  {!item.path && (
                    <Typography variant="body1" className={classes.linkHeader}>
                      {item.name}
                    </Typography>
                  )}
                  {item.items.map((v) => (
                    <AuthLink
                      href={v.path}
                      key={v.name}
                      auth={v.auth}
                      prefetch={false}>
                      <Link
                        className={cx(classes.navItem, classes.subNavItem)}
                        color="inherit"
                        variant="body1"
                        href={v.path}
                        onClick={() =>
                          trackEvent(
                            { ...v.gaEvent, category: GAEventCategory.Footer },
                            isMobile,
                          )
                        }>
                        {v.name}
                      </Link>
                    </AuthLink>
                  ))}
                </Box>
              ))}
            </Box>
            <Box display="flex" flexDirection="column">
              {navigation.map((v) => {
                if (v.name === "Login" && user) {
                  return null;
                }
                return (
                  <AuthLink
                    href={v.path}
                    key={v.path}
                    auth={v.auth}
                    prefetch={false}>
                    <Link
                      className={classes.navItem}
                      color="inherit"
                      variant="body1"
                      href={v.path}
                      onClick={() =>
                        trackEvent(
                          { ...v.gaEvent, category: GAEventCategory.Footer },
                          isMobile,
                        )
                      }>
                      {v.name}
                    </Link>
                  </AuthLink>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
