export interface GAEvent {
  action: string;
  category: string;
  label: string;
}

const trackEvent = ({ action, category, label }: GAEvent, isMobile = false) => {
  window.gtag("event", action, {
    event_category: `${isMobile ? "Mo. " : ""}${category}`,
    event_label: label,
  });
};

export default trackEvent;

export const GAEventAction = {
  Logo: "Logo",
  Terminal: "Terminal",
  Markets: "Markets",
  Labs: "Labs",
  Portfolio: "Portfolio",
  News: "News",
  Account: "Account",
  Search: "Search",
  Dominance: "Dominance",
  OpenMenu: "Open Menu",
  InfoEmail: "Info email",
  Login: "Login",
  TermsOfService: "Terms Of Service",
  PrivacyPolicy: "Privacy Policy",
  Assets: "Assets",
  Exchanges: "Exchanges",
  CustomDatePicker: "Custom Date Picker",
  ChartToggle: "Chart Toggle",
  ChartHover: "Chart Hover",
  OpenExchangeMatrix: "Open exchange matrix",
  AssetWebsiteLink: "Asset Website Link",
  ChartTimeFrame: "Chart time frame",
  OpenExchangeRow: "Open exchange row",
  CloseExchangeRow: "Close exchange row",
  OpenPairRow: "Open pair row",
  ClosePairRow: "Close pair row",
  ViewInTerminal: "View in Terminal",
  OrderbookDepth: "Orderbook Depth",
  OpenSortedPopover: "Open Sorted Popover",
  SortByPosition: "Sort by position",
  DateChart: "Date Chart",
  BoxEmail: "Box Email",
  ExchangeTelegram: "Exchange Telegram",
  ExchangeWebsite: "Exchange Website",
  FeesWebsite: "Fees Website",
  PriceChartHover: "Price Chart Hover",
  SelectAsset: "Select Asset",
  UnselectAsset: "Unselect Asset",
  SelectAssetDropdown: "Select Asset dropdown",
  ChangeAmount: "Change Amount",
  AddAsset: "Add Asset",
  SaveAsset: "Save Asset",
  CancelAdding: "Cancel Adding",
  SaveChanges: "Save Changes",
  EditAsset: "Edit Asset",
  DeleteAsset: "Delete Asset",
  OpenSetup: "Open Setup",
  CloseSetup: "Close Setup",
  Checkbox: "Check-box",
  SetCorrelationRangeMin: "Set Correlation Range Min",
  SetCorrelationRangeMax: "Set Correlation Range Max",
  SelectAllAssets: "Select All Assets",
  UnselectAllAssets: "Unselect All Assets",
  ApplySetup: "Apply setup",
  ClearSetup: "Clear setup",
  SearchFieldByAssets: "Search field by Assets",
  SelectFilter: "Select Filter",
  ChangePriority: "Change Priority",
  Asset: "Asset",
  ClosePopup: "Close pop-up",
  SingleBasketToggle: "Single / Basket toggle",
  SelectBaseAssetDropdown: "Select Base Asset Dropdown",
  SelectTradeAssetDropdown: "Select Trade Asset Dropdown",
  AmountField: "Amount Field",
  ProportionField: "Proportion Field",
  SelectAllExchanges: "Select All Exchanges",
  UnselectAllExchanges: "Unselect All Exchanges",
  SelectExchange: "Select Exchange",
  UnselectExchange: "Unselect Exchange",
  MinTradeExchangeField: "Min. Trade Exchange Field",
};

export const GAEventLabel = {
  Logo: "Logo",
  Terminal: "Terminal",
  SignIn: "Sign In",
  OpenSearch: "Open Search Field",
  Dominance: "Dominance",
  OpenMenu: "Open Menu",
  Email: "Email",
  Login: "Login",
  TermsOfService: "Terms Of Service",
  PrivacyPolicy: "Privacy Policy",
  CustomDatePicker: "Custom Date Picker",
  ChartToggle: "Chart Toggle",
  ChartHover: "Chart Hover",
  OpenExchangeMatrix: "Open exchange matrix",
  AssetWebsiteLink: "Asset Website Link",
  ChartTimeFrame: "Chart time frame",
  OpenExchangeRow: "Open exchange row",
  CloseExchangeRow: "Close exchange row",
  OpenPairRow: "Open pair row",
  ClosePairRow: "Close pair row",
  ViewInTerminal: "View in Terminal",
  OrderbookDepth: "Orderbook Depth",
  OpenSortedPopover: "Open Sorted Popover",
  SortByPosition: "Sort by position",
  DateChart: "Date Chart",
  BoxEmail: "Box Email",
  ExchangeTelegram: "Exchange Telegram",
  ExchangeWebsite: "Exchange Website",
  FeesWebsite: "Fees Website",
  PriceChartHover: "Price Chart Hover",
  SelectAsset: "Select Asset",
  UnselectAsset: "Unselect Asset",
  SelectAssetDropdown: "Select Asset dropdown",
  ChangeAmount: "Change Amount",
  AddAsset: "Add Asset",
  SaveAsset: "Save Asset",
  CancelAdding: "Cancel Adding",
  SaveChanges: "Save Changes",
  EditAsset: "Edit Asset",
  DeleteAsset: "Delete Asset",
  OpenSetup: "Open Setup",
  CloseSetup: "Close Setup",
  Checkbox: "Check-box",
  SetCorrelationRangeMin: "Set Correlation Range Min",
  SetCorrelationRangeMax: "Set Correlation Range Max",
  SelectAllAssets: "Select All Assets",
  UnselectAllAssets: "Unselect All Assets",
  ApplySetup: "Apply setup",
  ClearSetup: "Clear setup",
  SearchFieldByAssets: "Search field by Assets",
  SelectFilter: "Select Filter",
  ChangePriority: "Change Priority",
  ClosePopup: "Close pop-up",
  SingleBasketToggle: "Single / Basket toggle",
  SelectBaseAssetDropdown: "Select Base Asset Dropdown",
  SelectTradeAssetDropdown: "Select Trade Asset Dropdown",
  AmountField: "Amount Field",
  ProportionField: "Proportion Field",
  SelectAllExchanges: "Select All Exchanges",
  UnselectAllExchanges: "Unselect All Exchanges",
  SelectExchange: "Select Exchange",
  UnselectExchange: "Unselect Exchange",
  MinTradeExchangeField: "Min. Trade Exchange Field",
};

export const GAEventCategory = {
  Header: "Header",
  Footer: "Footer",
  Breadcrumb: "Breadcrumb",
  Pagination: "Pagination",
  HomePage: "Home Page",
  StickyHeader: "Sticky Header",
  AssetsPage: "Assets List",
  ExchangesPage: "List of Exchanges",
  AssetsOverview: "Assets - Overview",
  AssetsMarkets: "Assets - Markets",
  MiniTerminalAsset: "Mini terminal - Asset",
  MiniTerminalExchange: "Mini terminal - Exchange",
  AssetsLiquidity: "Assets - Liquidity Tab",
  ExchangeOverview: "Exchange - Overview",
  ExchangeMarkets: "Exchange - Markets",
  DeFi: "DeFi",
  MyPortfolio: "My Portfolio",
  PriceCorrelation: "Price Correlation",
  SetupPriceCorrelation: "Setup - Price Correlation",
  CompareAssets: "Compare Assets",
  SetupCompareAssets: "Setup - Compare Assets",
  TradeSimulatorPopup: "Trade Simulator - Pop-up",
  SetupTradeSimulator: "Setup - Trade Simulator",
  SingleTradeSimulatorResults: "Single Trade Simulator - Results",
  BasketTradeSimulatorResults: "Basket Trade Simulator - Results",
};
