import { Theme } from "@mui/material/styles";

import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { darkTheme, lightTheme } from "@lib/themes";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useStorage } from "@lib/hooks/useStorage";
// import { setCookie, parseCookies } from "nookies"

// const themeCookieKey = "___tloukneenr_theme"

export const AppThemeContext = createContext({});

interface AppThemeProviderProps {
  children: React.ReactNode;
}

// const isLightPreferred = () => parseCookies()[themeCookieKey] === "lightTheme"

export const AppThemeProvider = ({ children }: AppThemeProviderProps) => {
  const { toStorage, getStorage } = useStorage();
  const [theme, setAppTheme] = useState<Theme>(darkTheme);

  useEffect(() => {
    getStorage("theme") === "light"
      ? setAppTheme(lightTheme)
      : setAppTheme(darkTheme);
  }, []);

  const setTheme = useCallback((theme: Theme) => {
    // setCookie(
    //   null,
    //   themeCookieKey,
    //   theme === darkTheme ? "darkTheme" : "lightTheme",
    // )
    setAppTheme(theme);
    toStorage("theme", theme.palette.mode);
  }, []);

  const renderedTheme = useMemo(() => theme || darkTheme, [theme]);

  return (
    <MuiThemeProvider theme={renderedTheme}>
      <AppThemeContext.Provider
        value={{
          setTheme,
          theme: renderedTheme,
        }}>
        {children}
      </AppThemeContext.Provider>
    </MuiThemeProvider>
  );
};

interface UseAppThemeProps {
  setTheme?: Dispatch<SetStateAction<Theme>>;
  theme?: Theme;
}

export function useAppTheme(): UseAppThemeProps {
  return useContext(AppThemeContext);
}
