type StoreInBrowserStorage = <T>(key: string, item: T) => void;
type GetValueForKeyInBrowserStorage = <T>(key: string) => T | null;
type RemoveFromBrowserStorage = (key: string) => void;

export const storeInBrowserStorage: StoreInBrowserStorage = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item));
};

export const getValueForKeyInBrowserStorage: GetValueForKeyInBrowserStorage = (
  key,
) => {
  const item = localStorage.getItem(key);

  return item ? JSON.parse(item) : null;
};

export const removeFromBrowserStorage: RemoveFromBrowserStorage = (key) => {
  localStorage.removeItem(key);
};
