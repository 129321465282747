import { useEffect, useState } from "react";

export const useScrollPosition = () => {
  const [scrollPos, setScrollPos] = useState<number>(0);
  const [visivility, setVisivility] = useState<boolean>(true);

  const handleScroll = () => {
    setScrollPos(window.scrollY);
  };

  useEffect(() => {
    if (visivility && scrollPos > 400) setVisivility(false);
  }, [scrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    scrollPos: visivility ? scrollPos : 400,
    scrollPosition: scrollPos,
  };
};
