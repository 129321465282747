import { Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";

type SkeletonImgProps = {
  width: string | number;
  height: string | number;
  src: string;
  alt?: string;
  skeletonProps?: { variant?: "text" | "rectangular" | "circular" };
  fallback?: null | JSX.Element;
};

// image loading with skeleton and fallback
export default function SkeletonImg({
  width,
  height,
  src,
  alt,
  skeletonProps,
  fallback = null,
}: SkeletonImgProps) {
  const loaded = useLoaded({ src });

  if (loaded === false) {
    return <Skeleton width={width} height={height} {...skeletonProps} />;
  } else if (loaded === "error") {
    return fallback;
  } else {
    return <img src={src} style={{ width, height }} alt={alt} />;
  }
}

// from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Avatar/Avatar.js
// needed to support SSR
function useLoaded({
  src,
  srcSet,
}: {
  src?: string;
  srcSet?: string;
}): string | boolean {
  const [loaded, setLoaded] = useState<string | boolean>(false);

  useEffect(() => {
    if (!src && !srcSet) {
      return setLoaded("error");
    }

    setLoaded(false);

    let active = true;
    const image = new Image();
    image.src = src;
    if (srcSet) {
      image.srcset = srcSet;
    }
    image.onload = () => {
      if (!active) {
        return;
      }
      setLoaded("loaded");
    };
    image.onerror = () => {
      if (!active) {
        return;
      }
      setLoaded("error");
    };

    return () => {
      active = false;
    };
  }, [src, srcSet]);

  return loaded;
}
