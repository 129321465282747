/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeStyles } from "tss-react/mui";
import createCache from "@emotion/cache";

import { ITheme } from "./types";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

function makeStylesWrapper<T = any>(
  styles: (theme: ITheme, props: T) => Record<string, any>,
) {
  const useStyles = makeStyles<T>()(styles);

  return (props?: T) => {
    const { classes } = useStyles(props);
    return classes;
  };
}
export default makeStylesWrapper;
