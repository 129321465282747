import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useCallback,
} from "react";
import { AlertColor } from "@mui/material/Alert";
// import Slide from "@mui/material/Slide";
import SnackbarAlert from "@components/SnackbarAlert";

export const SnackbarContext = createContext<Snackbars>({});
// const AUTO_DISMISS = 5000;

// function SlideTransition(props) {
//   return <Slide {...props} direction="up" />;
// }

interface SnackbarProviderProps {
  children: React.ReactNode;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbarText, setSnackbarText] = useState<string>("");
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>(
    "success",
  );

  // utility function
  const createSnackbar = useCallback((text: string, severity?: AlertColor) => {
    setShowSnackbar(true);
    setSnackbarText(text);

    if (typeof severity !== "undefined") {
      setSnackbarSeverity(severity);
    }
  }, []);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
        setShowSnackbar,
        snackbarText,
        setSnackbarText,
        snackbarSeverity,
        setSnackbarSeverity,
        createSnackbar,
      }}>
      {children}
      {/* <Snackbar
        autoHideDuration={AUTO_DISMISS}
        open={showSnackbar}
        TransitionComponent={SlideTransition}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarText}
        </MuiAlert>
      </Snackbar> */}
      <SnackbarAlert
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
        title={snackbarText}
        open={showSnackbar}
      />
    </SnackbarContext.Provider>
  );
};

// fix optionality of this
interface Snackbars {
  showSnackbar?: boolean;
  setShowSnackbar?: Dispatch<SetStateAction<boolean>>;
  snackbarText?: string;
  setSnackbarText?: Dispatch<SetStateAction<string>>;
  snackbarSeverity?: AlertColor;
  setSnackbarSeverity?: Dispatch<SetStateAction<AlertColor>>;
  createSnackbar?: (text: string, severity?: AlertColor) => void;
}

export function useSnackbars(): Snackbars {
  return useContext(SnackbarContext);
}
