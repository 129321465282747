export const types = {
  All: {
    name: "All",
    type: "all",
    marketType: "all",
    disabled: true,
  },
  Spot: {
    name: "Spot",
    type: "spot",
    marketType: "spot",
    disabled: false,
  },
  Futures: {
    name: "Futures",
    type: "futures",
    marketType: "futures",
    disabled: true,
  },
  Perpetual: {
    name: "Perpetual",
    type: "perpetuals",
    marketType: "perp",
    disabled: true,
  },
  Options: {
    name: "Options",
    type: "options",
    marketType: "options",
    disabled: true,
  },
};

export const defaultCategoryId = 0;

export const overviewCategories = Object.values(types)
  .filter((item) => !item.disabled)
  .map((v) => v.name);

export const getTypes = (object, showAll = true) => {
  const arr = [];

  if (object?.[`is_spot`] && !types["Spot"].disabled)
    arr.push(types["Spot"].name);
  if (object?.[`is_futures`] && !types["Futures"].disabled)
    arr.push(types["Futures"].name);
  if (object?.[`is_perpetuals`] && !types["Perpetual"].disabled)
    arr.push(types["Perpetual"].name);
  if (object?.[`is_options`] && !types["Options"].disabled)
    arr.push(types["Options"].name);
  if (arr.length > 1 && showAll && !types["All"].disabled)
    arr.unshift(types["All"].name);

  return arr;
};

export const getAssetTypes = (object, showAll = true) => {
  const arr = [];

  if (object?.[`volume_1m_usd_spot`] && !types["Spot"].disabled)
    arr.push(types["Spot"].name);
  if (object?.[`futures_volume_usd_1m`] && !types["Futures"].disabled)
    arr.push(types["Futures"].name);
  if (object?.[`perp_volume_usd_1m`] && !types["Perpetual"].disabled)
    arr.push(types["Perpetual"].name);
  if (object?.[`is_options`] && !types["Options"].disabled)
    arr.push(types["Options"].name);
  if (arr.length > 1 && showAll && !types["All"].disabled)
    arr.unshift(types["All"].name);

  return arr;
};

export const getTypeByName = (name) => {
  return types[name]?.type;
};

export const getTypeByNameMarket = (name) => {
  return types[name]?.marketType;
};

export const isDerivatives = (name, onlySpot = false) => {
  return !(name === types.Spot.name || (name === types.All.name && !onlySpot));
};

export const isDerivativesCoin = (typesArr) => {
  return typesArr.some(
    (coin) => coin === types.Futures.name || coin === types.Perpetual.name,
  );
};

export const byContract = (name) => {
  if (!(name === types.Spot.name || name === types.All.name)) return "Contract";
  return "Pair";
};
